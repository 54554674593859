var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StrictMode, useEffect, useState } from "react";
import { FilterSelectionProvider, localStorageFilters, urlParamsFilter, isEmptyFilter, } from "./contexts/filterSelection/filtersSelectionContext";
import { Filter } from "./presentation/components/base/Filter";
import { getInitialFilterDataUseCase } from "./modules/filter/domain/usecases/getinitialFilterData";
import { getIdsAndLabels } from "shared-schemas/helpers/getIdsAndLabels";
import { LoadingDisplay } from "./presentation/components/loading/LoadingDisplay";
import "./app.scss";
export function FilterEntrypoint(_a) {
    var httpClient = _a.httpClient, authenticationData = _a.authenticationData;
    var header = "Bearer ".concat(authenticationData === null || authenticationData === void 0 ? void 0 : authenticationData.accessToken);
    httpClient.setAuthenticationHeader(header);
    var _b = useState(), initialData = _b[0], setInitialData = _b[1];
    var usecase = getInitialFilterDataUseCase(httpClient);
    var windowURLparams = new URLSearchParams(window.location.search);
    var filters = urlParamsFilter(windowURLparams);
    if (isEmptyFilter(filters)) {
        filters = localStorageFilters();
    }
    useEffect(function () {
        usecase(filters).then(setInitialData).catch(console.error);
    }, []);
    if (!initialData) {
        return _jsx(LoadingDisplay, {});
    }
    var initialFilterData = setFilterFromInitialData(initialData);
    var initialOptionsData = getArrays(initialData);
    return (_jsx(StrictMode, { children: _jsx(FilterSelectionProvider, __assign({ initial: initialFilterData }, { children: _jsx(Filter, { httpClient: httpClient, initialOptions: initialOptionsData }) })) }));
}
export function setFilterFromInitialData(initialData) {
    var selectedDate = initialData.selectedDate
        ? new Date(initialData.selectedDate)
        : new Date();
    initialData.selectedDate = selectedDate.toISOString();
    var arrays = getArrays(initialData);
    var shift = arrays.shift[0] || null;
    return {
        location: findFirstBy(arrays.location, "location", initialData.locationId),
        area: findFirstBy(arrays.area, "area", initialData.areaId),
        subarea: findFirstBy(arrays.subarea, "subarea", initialData.subareaId),
        equipment: findFirstBy(arrays.equipment, "equipment", initialData.equipmentId),
        machine: findFirstBy(arrays.machine, "machine", initialData.machineId),
        checklist: findFirstBy(arrays.checklist, "checklist", initialData.checklistId),
        shift: shift,
        selectedDate: selectedDate,
        shiftConfig: arrays.shiftConfig,
    };
}
export function findFirstBy(array, key, searchParam) {
    var id = getIdsAndLabels(key).id;
    return array.find(function (item) { return item[id] === searchParam; });
}
export function getArrays(initialData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var weekday = new Date(Date.parse(initialData.selectedDate)).getDay() || 7;
    var weekDayShifts = (_c = (_b = (_a = initialData.shifts) === null || _a === void 0 ? void 0 : _a.items[0]) === null || _b === void 0 ? void 0 : _b.shiftWeekDayConfig) === null || _c === void 0 ? void 0 : _c.find(function (config) { return config.weekday === weekday; });
    return {
        location: ((_d = initialData.locations) === null || _d === void 0 ? void 0 : _d.items) || [],
        area: ((_e = initialData.areas) === null || _e === void 0 ? void 0 : _e.items) || [],
        subarea: ((_f = initialData.subareas) === null || _f === void 0 ? void 0 : _f.items) || [],
        equipment: ((_g = initialData.equipments) === null || _g === void 0 ? void 0 : _g.items) || [],
        machine: ((_h = initialData.machines) === null || _h === void 0 ? void 0 : _h.items) || [],
        checklist: ((_j = initialData.checklists) === null || _j === void 0 ? void 0 : _j.items) || [],
        shift: (weekDayShifts === null || weekDayShifts === void 0 ? void 0 : weekDayShifts.shifts) || [],
        shiftConfig: (_k = initialData.shifts) === null || _k === void 0 ? void 0 : _k.items[0],
    };
}
export default FilterEntrypoint;
